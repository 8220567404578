import {isNil} from "lodash";

import WidgetEventFilter from "../rooms/widget-event-filter";
import { AllEventsFilter } from "../rooms/all-events-filters";

const optionsProperty = Symbol("options");
const widgetProperty = Symbol("widget");
const valuesProperty = Symbol("values");

const OutputsProxy = {
  get(
    {
      [optionsProperty]: options,
      [widgetProperty]: widget,
      [valuesProperty]: values
    },
    property
  ) {
    let output = options[property];
    if (!output) {
      throw new Error(`OutputProxy: Wrong output '${property}'`);
    }
    return values[property];
  },

  set(
    {
      [optionsProperty]: options,
      [widgetProperty]: widget,
      [valuesProperty]: values
    },
    property,
    value
  ) {
    const output = options[property];
    if (isNil(output)) {
      throw new Error(`OutputProxy: Wrong output '${property}'`);
    }

    values[property] = value;

    if (value) {
      widget.openPanel();
    }

    widget.$eventBuss.emit(`${widget.entity.id}:${property}`, value);
    return true;
  },

  ownKeys({[optionsProperty]: options}) {
    return Object.keys(options);
  }
};

class WidgetInputs {
  constructor(widget) {
    const entity = widget.entity || {}
    const inputs = widget.meta.inputs || {}
    this._widget = widget
    // to destroy all event listeners correctly
    this._handlers = {}
    if (isNil(entity.inputs)) return
    // create event listeners from inputs (general like panel and error events
    // and events from component)
    Object.entries(inputs).forEach(([key]) => {
      // event is data structure { isEvent: true, value: "Order:Refresh" } from widget config
      const event = entity.inputs[key];
      // callbacks from component
      const winputs = widget.$options.inputs || {}
      const handler = winputs[key]
      if (event && handler) {
        const cb = value => {
          handler.apply(widget, [value])
        }
        widget.$eventBuss.on(event.value, cb)
        this._handlers[event] = cb
      }
    })
  }

  destroy() {
    Object.entries(this._handlers).forEach(([event, handler]) =>
      this._widget.$eventBuss.off(event, handler)
    );
    this._handlers = {};
  }
}

class WidgetEvents {
  constructor(widget) {
    this._widget = widget
    this._handlers = {}

    if (this._widget.entity == null) {
      return;
    }

    const inputEventConfig = this._widget.entity.input_event_config || {};
    let eventFilters = [...(inputEventConfig.filters || [])]
    this._filters = inputEventConfig.allEvents
      ? [AllEventsFilter]
      : [...(widget.meta.defaultFilters || []), ...eventFilters]

    this._serviceHandlers = {}
    const serviceFilter = new WidgetEventFilter({
      eventCatalogTypes: [],
      eventCatalogs: [],
      directObjs: [{ id: widget.entity.id }],
      subjects: [],
      uuid: `Widget-service_filter-${widget.entity.id}`,
    })
    this._serviceFilters = [serviceFilter]

    const eventReceiver = widget.$options.eventReceiver || {}
    Object.entries(eventReceiver).forEach(([funcName, handler]) => {
      const cb = (value) => {
        handler.apply(widget, [value])
      }
      widget.$rooms.subscribeWidget({
        filters: this._filters,
      }, cb)
      this._handlers[funcName] = cb
    })

    const serviceEventReceiver = widget.$options.serviceEventReceiver || {}
    Object.entries(serviceEventReceiver).forEach(([funcName, handler]) => {
      const cb = (value) => {
        handler.apply(widget, [value])
      }
      widget.$rooms.subscribeWidget({
        filters: this._serviceFilters,
      }, cb)
      this._serviceHandlers[funcName] = cb
    })
  }

  destroy() {
    if (this._handlers) {
      Object.entries(this._handlers).forEach(([funcName, handler]) =>
        this._widget.$rooms.unsubscribeWidget({
          filters: this._filters,
        }, handler)
      );
    }
    if (this._serviceHandlers) {
      Object.entries(this._serviceHandlers).forEach(([funcName, handler]) =>
        this._widget.$rooms.unsubscribeWidget({
          filters: this._serviceFilters,
        }, handler)
      );
    }
    this._serviceHandlers = {};
    this._handlers = {};
  }

}

class WidgetSettings {
  constructor(widget) {
    this.widget = widget
    const entity = widget.entity
    const settings = widget.meta.settings || {};
    // call settings handler from component with value from server (widget.settings)
    Object.entries(settings).forEach(([key]) => {
      const handler = !isNil(widget.$options.settings) ? widget.$options.settings[key] : widget.$options[key];
      const value = !isNil(entity.settings) ? entity.settings[key] : entity[key];
      if (handler) {
        handler.apply(widget, [value]);
      }
    });
  }

  destroy() {
  }
}

const WidgetMixin = {
  props: {
    entity: {},
    meta: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      $settings: null,
      $inputs: null,
      $outputs: null,
      $eventReceiver: null,
    };
  },

  eventReceiver: {
    tryEvent(value) {
      if (isNil(value)) {
        return
      }
      // console.log('room event mixin', value)
    },
  },

  serviceEventReceiver: {
    rerender(value) {
      if (isNil(value)) {
        return
      }
      // console.log('service event receiver rerender', value)
    },
  },

  methods: {
    async wait(count) {
      return new Promise(res => setTimeout(res, count));
    },

    getWidgetEventFilters () {
      if (this.$eventReceiver._filters) {
        return Object.values(this.$eventReceiver._filters).flat(1)
      } else {
        return [];
      }
    },

    hasAllEventFilter () {
      const filters = this.getWidgetEventFilters()
      return filters.some((f) => f.name === AllEventsFilter.name)
    },

    getAllWidgetsSettings () {
      const currentCustomFilters = this.getWidgetEventFilters()
      const eventCatalogs = currentCustomFilters.map((f) => f.eventCatalogs).flat(1).filter((item) => !!item)
      const eventCatalogTypes = currentCustomFilters.map((f) => f.eventCatalogTypes).flat(1).filter((item) => !!item)
      const directObjs = currentCustomFilters.map((f) => f.directObjs).flat(1).filter((item) => !!item)
      const subjects = currentCustomFilters.map((f) => f.subjects).flat(1).filter((item) => !!item)
      return { eventCatalogs, eventCatalogTypes, directObjs, subjects }
    },

    openPanel() {
      if (!this.entity || !this.entity.panel) {
        return;
      }

      const {activeWidget, ...settings} = this.entity.panel;
      if (activeWidget) {
        this.$panel.openWidget(activeWidget, settings);
      }
    },
  },

  async mounted() {
    let outputs = {
      [optionsProperty]: this.meta.outputs || {},
      [widgetProperty]: this,
      [valuesProperty]: {}
    };

    // Порядок нельзя менять
    this.$settings = new WidgetSettings(this);
    this.$outputs = new Proxy(outputs, OutputsProxy);
    this.$inputs = new WidgetInputs(this);
    this.$eventReceiver = new WidgetEvents(this)
  },
  beforeDestroy() {
    if (!isNil(this.$inputs)) {
      this.$inputs.destroy();
    }
    if (!isNil(this.$settings)) {
      this.$settings.destroy();
    }
    if (!isNil(this.$eventReceiver)) {
      this.$eventReceiver.destroy();
    }
  }
};

export default WidgetMixin;
