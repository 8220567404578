import Vue from 'vue';
import User from './user';

async function getData($http, url) {
  try {
    return await $http.getItem(url);
  } catch (error) {
    console.log(`error loading '${url}'`);
    console.log(error);
    return null;
  }
}

async function createContext($http) {
  let userData = await getData($http, '/api/v1/users/current');
  let platformData = await getData($http,'/api/v1/application/settings');

  return new Vue({
    data() {
      return {
        user     : new User(userData),
        platform : platformData,
        drawer   : false,
        subtitle : null
      }
    },

    methods: {
      updateTitle() {
        window.document.title =
          this.platform.platformName &&
          this.platform.platformName[this.$locale.current] || '';
      }
    },

    created() {
      this.updateTitle();
    }
  });
}

export default createContext;
