import VueI18n from 'vue-i18n';
import Plugin from '../../plugin';
import Messages from '../../../locales';

import 'moment/locale/ru';

const LOCALES        = Object.keys(Messages);
const DEFAULT_LOCALE = LOCALES[0];

class LocalePlugin extends Plugin {

  static install(Vue) {
    Vue.use(VueI18n);

    let plugin = new LocalePlugin();

    Vue.prototype.$locale = plugin;
    Vue.prototype.$t      = (key, ...params) => plugin.translate(key, ...params);
    Vue.prototype.$te     = (error)          => plugin.getErrorMessage(error);
  }

  constructor() {
    super();

    this._i18n = new VueI18n({
      locale         : DEFAULT_LOCALE,
      fallbackLocale : DEFAULT_LOCALE,
      messages       : Messages,
      silentTranslationWarn: true,
    });
  }

  init(app) {
    this.$root = app.root.$root;
    this.current = 'ru';
  }

  register(locale, messages) {
    this._i18n.mergeLocaleMessage(locale, messages);
  }

  translate(key, ...params) {
    return this._i18n.t(key, ...params);
  }

  getErrorMessage(error) {
    let result = null;
    if (error) {
      if (error.response && error.response.data) {
        result = error.response.data;
      } else {
        result = error.message || error;
      }
    }
    return result;
  }

  get list() {
    return LOCALES;
  }

  get current() {
    return this._i18n.locale;
  }
  set current(value) {
    if (LOCALES.indexOf(value) < 0 || this.current === value) {
      return;
    }
    this._i18n.locale = value;
    this.$root.$context.updateTitle();
  }
}

export default LocalePlugin;
