import Plugin from "../../plugin";

const THEMES = ['light', 'dark'];

class ThemePlugin extends Plugin {

  static install(Vue) {
    Vue.prototype.$theme = new ThemePlugin();
  }

  constructor() {
    super();
  }

  init(app) {
    this.$root   = app.root.$root;
    this.current = 'dark';
  }

  get list() {
    return THEMES;
  }

  get current() {
      return this.$root.$vuetify.theme.dark
          ? THEMES[1]
          : THEMES[0];
  }
  set current(value) {
      if (THEMES.indexOf(value) < 0) {
          return;
      }
      this.$root.$vuetify.theme.dark = value !== THEMES[0];
  }
}

export default ThemePlugin;
