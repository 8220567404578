import Plugin from "../plugin";
import LocalePlugin from "./locale/locale-plugin";
import EventBusPlugin from "./event-bus/event-bus-plugin";
import ThemePlugin from "./theme/theme-plugin";
import PanelPlugin from "./panel/panel-plugin";
import RoomPlugin from "./rooms/rooms-plugin";

const plugins = [
  LocalePlugin,
  EventBusPlugin,
  ThemePlugin,
  PanelPlugin,
  RoomPlugin,
];

export default {
  install(Vue) {
    plugins.forEach((p) => p.install(Vue));
  },
  async init(app) {
    await Plugin.init(app);
  }
}
