import Vue from "vue"
import io from "socket.io-client"
import SocketEvents from "./socket-events"

const isPromise = h => h && typeof h.then === "function"

class EventBuss {
  init() {
    this._socket = this._createSocket();
    this._socket.compress(true);
    this._vue = this._createVueComponent();

    this._tasks = {};
    // for keeping events without handlers??
    this._subscribers = new Map();
    // for keeping handlers of events (from different components?)
    this._handlers = new Map();
    this._subscribeOnSocket();
    this._pingInterval = setInterval(async () => {
      try {
        await this._vue.$eva.$http.getItem('/api/v1/ping');
        console.log('server ping ok');
      } catch (error) {
        console.log('ping error');
        console.log(error);
      }
    }, 300000);

    this._socket.connect();
  }

  _processResponse(task) {
    const local = this._tasks[task.id];
    if (local) {
      const {error, serverMessage, errorType, type, options} = task
      if (error) {
        task.error = {...task.error, type, options}
        if (serverMessage) task.error = {...task.error, serverMessage}
        if (errorType) task.error = {...task.error, errorType}
      }
      //TODO: наличие закоментированных строк приводило к тому, что если падал сервис, то запрос ожидался вечно
      //      проверить не приведет ли это к ошибкам в дуругих виджетах
      /*if (!error) {*/
      local.done(task.error, task.res);
      delete this._tasks[task.id];
      /*}*/
    }
  }

  _createSocket() {
    return io("", {
      transports: ["websocket"],
      cors: {
        origin: "*",
      },
      autoConnect: false,
      reconnectionAttempts: 1000,
      reconnectionDelayMax: 1000
    });
  }

  _subscribeOnSocket() {
    this._socket.onAny((eventName, data) => {
      switch (eventName) {
        case SocketEvents.RESPONSE:
          this._processResponse(data)
          break
        default: {
        }
      }
    })
  }

  _createVueComponent() {
    return new Vue({});
  }

  emit(eventName, event) {
    const hasArguments = Array.isArray(event) ? event.filter(existedEvent => !!existedEvent).length > 0 : !!event; //WTF???
    // Нужно для корректной работы подписок динамически отображаемых компонентов (в модальных окнах/на выдвижной панели)
    if (!this._subscribers.has(eventName) && hasArguments) {
      this._subscribers.set(eventName, event);
    }
    if (Array.isArray(event)) {
      event.forEach(e => this._vue.$emit(eventName, e))
    } else {
      this._vue.$emit(eventName, event);
    }
  }

  clientEmit(eventName, event) {
    this._vue.$emit(eventName, event);
  }

  clientOn(eventName, handler) {
    this._vue.$on(eventName, handler);
  }

  clientOff(eventName, handler) {
    this._vue.$off(eventName, handler);
  }

  processEvent(event, payload) {
    const handlers = this._handlers.get(event) || [];
    for (const handler of handlers) {
      if (isPromise(handler)) {
        // асинхронный обработчик
        handler(payload).then(() => {});
      } else {
        handler(payload);
      }
    }
  }

  on(event, handler) {
    const payload = this._subscribers.get(event);
    const handlersCached = this._handlers.get(event) || [];
    const alreadySubscribed = !!handlersCached.length;
    handlersCached.push(handler);
    this._handlers.set(event, handlersCached);
    // console.log('event bus on', event, handler, handlersCached)
    if (!alreadySubscribed) {
      this._vue.$on(event, payload => this.processEvent(event, payload));
    }
    if (!!payload) {
      // вызов обработчика, для обработки ранее прошедших событий
      this.processEvent(payload);
      this._subscribers.set(event, null);
    }
  }

  off(event, handler) {
    const handlers = this._handlers.get(event) || [];
    const idx = handlers.findIndex(h => h === handler);
    if (idx >= 0) {
      handlers.splice(idx, 1);
    }
    this._subscribers.delete(event);
    if (!handlers.length) {
      this._handlers.delete(event);
      this._vue.$off(event, payload => this.processEvent(event, payload))
    }
  }

  get socket () {
    return this._socket
  }

  get vueInstance() {
    return this._vue
  }
}

export default EventBuss;
