// todo: remove after switch all platform in rooms
import {v4 as uuidv4} from "uuid";

const ROOM_PREFIX = "room_"
const ALL_FILTER_EVENTS_NAME = "AllEventsFilter"

// todo: move to utils
function hashCode(str = '') {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    let character = str.charCodeAt(i);
    hash = (hash << 5) - hash + character;
    hash = hash & hash;
  }
  return hash;
}

class WidgetEventFilter {

    constructor (filterObj = {} ) {
        const {
          eventCatalogTypes = [],
          eventCatalogs = [],
          directObjs = [],
          subjects = [],
          uuid = '',
          name,
          prepareEvent
        } = filterObj;
        this.eventCatalogTypes = eventCatalogTypes;
        this.eventCatalogs = eventCatalogs;
        this.directObjs = directObjs;
        this.subjects = subjects;
        this.uuid = uuid;
        this.name = name;
        // function or class
        this.prepareEvent =
          typeof prepareEvent === "function"
            ? prepareEvent
            : (event) => {
              return {
                ...event,
                id: uuidv4(),
                source: event.id,
              }
            }
    }

    getFilterValues () {
        return {
            eventCatalogTypes: this.eventCatalogTypes.map((item) => item.name),
            eventCatalogs: this.eventCatalogs.map((item) => item.sys_name),
            directObjs: this.directObjs.map((item) => item.id),
            subjects: this.subjects.map((item) => item.id),
        }
    }

    getHash () {
        const values = this.getFilterValues()
        const flatValues = Object.values(values).flat(1)
        const hash = hashCode(flatValues.join(','))
        const filterHash = flatValues.map((val) => (val || '').split(":")[0]).join('-').toLowerCase()
        return `${filterHash}::${hash}`
    }

    getEventNames () {
      const values = this.getFilterValues()
      let eventNames = []
      if (values.eventCatalogs.length !== 0) {
        eventNames = [...values.eventCatalogs]
      }
      if (eventNames.length === 0 && values.eventCatalogTypes.length !== 0) {
        eventNames = [...values.eventCatalogTypes]
      }
      if (eventNames.length === 0 && values.directObjs.length !== 0) {
        eventNames = [...values.directObjs]
      }
      if (eventNames.length === 0) {
        eventNames = [...values.subjects]
      }
      return eventNames.map((name) => `${ROOM_PREFIX}${name}`)
    }

    getEventNameByEvent (event) {
      const values = this.getFilterValues()
      const eventNames = this.getEventNames()
      // todo: make it NORMAL
      const keyAll = ALL_FILTER_EVENTS_NAME
      const isFilterAllEvents = values.eventCatalogTypes[0] === keyAll
      if (isFilterAllEvents) {
        return eventNames[0]
      }

      const pEventNames = [];

      for (let key of ['eventType', 'event', 'direct_obj', 'subject']) {
        switch(key) {
          case 'eventType': {
            if (
              event.event_type
              && event.event_type.ref_catalog_type
              && event.event_type.ref_catalog_type.name
            ) {
              pEventNames.push(`${ROOM_PREFIX}${event.event_type.ref_catalog_type.name}`);
            }
            break;
          }
          case 'event': {
            if (event && event.event_type && event.event_type.sys_name) {
              pEventNames.push(`${ROOM_PREFIX}${event.event_type.sys_name}`);
            }
            break;
          }
          case 'direct_obj': {
            if (event && event.direct_obj && event.direct_obj.id) {
              pEventNames.push(`${ROOM_PREFIX}${event.direct_obj?.id}`);
            }
            break;
          }
          default: {
            if (event && event.subject && event.subject.id) {
              pEventNames.push(`${ROOM_PREFIX}${event.subject?.id}`);
            }
            break;
          }
        }
      }

      for (const eventName of pEventNames) {
        if (eventNames.includes(eventName)) {
          return eventName
        }
      }
    }

    isMatchEvent (event) {
      const values = this.getFilterValues()
      const keyAll = ALL_FILTER_EVENTS_NAME
      const isFilterAllEvents = values.eventCatalogTypes[0] === keyAll
      if (isFilterAllEvents) {
        return true
      }

      const typeEvent = event.event_type.ref_catalog_type.name;

      return [
        'eventCatalogTypes',
        'eventCatalogs',
        'directObjs',
        'subjects'
      ].some((key) => {
        let includeVal;
        if (key === 'eventCatalogTypes') {
          includeVal = typeEvent;
        } else if (key === 'eventCatalogs') {
          includeVal = event.event_type.sys_name;
        } else if (key === 'directObjs') {
          includeVal = event.direct_obj?.id;
        } else {
          includeVal = event.subject?.id;
        }

        return values[key].length !== 0 && values[key].some(
          (val) => val.includes(includeVal));
      });
    }
}

export default WidgetEventFilter;
