import Plugin from "../../plugin";
import EventBus from './event-bus';

class EventBusPlugin extends Plugin {

    static install(Vue) {
      Vue.prototype.$eventBuss = new EventBus();
    }

    constructor() {
        super();
    }
}

export default EventBusPlugin;
